@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100;
  }
}

@layer components {
  .btn-primary {
    @apply px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:bg-primary-500 dark:hover:bg-primary-600 dark:focus:ring-offset-gray-900;
  }
  
  .card {
    @apply bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200;
  }
  
  .input {
    @apply block w-full rounded-md shadow-sm sm:text-sm
    border-gray-300 
    focus:border-primary-500 focus:ring-primary-500
    dark:bg-gray-700 dark:border-gray-600 dark:text-white
    dark:focus:border-primary-500 dark:focus:ring-primary-500
    dark:placeholder-gray-400;
  }

  .select {
    @apply block w-full rounded-md shadow-sm sm:text-sm
    border-gray-300
    focus:border-primary-500 focus:ring-primary-500
    dark:bg-gray-700 dark:border-gray-600 dark:text-white
    dark:focus:border-primary-500 dark:focus:ring-primary-500;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 